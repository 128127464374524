.popup-content {
    max-width: 600px;
    width: 90%!important;


}
.team-root{
    
    .closeroot {
        cursor: pointer;
        position: absolute;
        display: flex;
        padding: 2px 5px;
       justify-content: center;
       align-items: center;
        right: -10px;
        top: -10px;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #cfcece;
        width: 28px;
        height: 28px;
      }
}