.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-team .slick-center.slick-current img{
  width: 150px;
}

.slick-center.slick-current {
  margin-top: -20px;
}

.navbar-light .navbar-nav .nav-link{
  color:#7B94B5 !important
}

.dropdown-menu{
  background-color: rgba(231, 241, 251, 0.5) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0,0,0,.15) !important;
  border-radius: .25rem !important;
  box-sizing: border-box !important;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.12) !important;
  backdrop-filter: blur(10px) !important;
  min-width: 12rem !important;
  padding: 0.7rem 0 !important;
  border: 1px solid rgba(231, 241, 251, 0.5) !important;
  border-radius: 20px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.journey-slider .slick-track {
  height: 490px;
  display:flex;
}
.journey-slider .slick-track:after {
  content: "";
  height: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #E2E2E2;
  display: block;
}
.journey-slider .slick-slide:nth-child(odd) {
  align-self: flex-start;

}
.journey-slider .slick-slide:nth-child(even) {
  align-self: flex-end;

}

.journey-slider .slick-slide {
  position: relative;
  background: #ECF5FC;
  display: flex !important;
  height: 205px;
  width: 205px;
  border-radius: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 8px;
  padding: 15px;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25);
}


.journey-slider .slick-list {
  padding-top: 40px;
}

.journey-slider .slick-slide:nth-child(odd):before {
  content: "";
  position: absolute;
  background: #ECF5FC;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  top: calc(100% + 12px);
  z-index: 99;
}


.journey-slider .slick-slide:nth-child(odd):after {
  position: absolute;
  background: #6497C7;
  width: 18px;
  height: 18px;
  content: "";
  border-radius: 100%;
  top: calc(100% + 21px);
  z-index: 99;
}


.journey-slider .slick-slide:nth-child(even):before {
  content: "";
  position: absolute;
  background: #ECF5FC;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  bottom: calc(100% + 12px);
  z-index: 99;
}

.journey-slider .slick-slide:nth-child(even):after {
  position: absolute;
  background: #6497C7;
  width: 18px;
  height: 18px;
  content: "";
  border-radius: 100%;
  bottom: calc(100% + 21px);
  z-index: 99;
}

.journey-slider .slick-slide div {
  outline: none;
}

button.navbar-toggler:focus, span.navbar-toggler-icon:focus {
  outline: none;
}

/* .bg-light{
} */
#circle-loader-wrap {
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  width: 400px;
  height: 400px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) inset;
  background-color: #ecf5fc;
  border-radius: 400px;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

#circle-loader-wrap:after {
  content: '';
  position: absolute;
  left: 30px;
  top: 30px;
  width: 340px;
  height: 340px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#circle-loader-wrap div {
  overflow: hidden;
  position: absolute;
  width: 50%;
  height: 100%;
}

#circle-loader-wrap .loader {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  background-color: #bcd6f2;
}

#circle-loader-wrap .left-wrap {
  left: 0;
}

#circle-loader-wrap .left-wrap .loader {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transform-origin: 0 50% 0;
  -webkit-transform-origin: 0 50% 0;
  animation: loading-left 20s infinite linear;
  -webkit-animation: loading-left 20s infinite linear;
}

#circle-loader-wrap .right-wrap {
  left: 50%;
}

#circle-loader-wrap .right-wrap .loader {
  left: -100%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  transform-origin: 100% 50% 0;
  -webkit-transform-origin: 100% 50% 0;
  animation: loading-right 20s infinite linear;
  -webkit-animation: loading-right 20s infinite linear;
}
.slick-player .slick-list{
padding-top: 80px!important;
height: 400px;
}
.slick-player .slick-slide{
  display: flex;
  justify-content: center;
  align-items: center;
/*  width: 250px!important;
  height: 250px!important; */
}
.slick-player .slick-slide .vid{
   width: 250px!important;
  height: 250px!important; 
}
.slick-player .slick-current .vid{
  width: 350px!important;
  height: 350px!important;
  margin: -50px 0px 0px 0px; 
}
.slick-player .slick-current77UJ{
  /* width: 350px!important;
  height: 350px!important; */
}



@property --p{
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.circular-loader {
  --p:20;
  --b:22px;
  --c:#bfd8f3;
  --w:150px;
  
  width:var(--w);
  aspect-ratio:1;
  position:absolute;
  display:inline-grid;
  margin:5px;
  place-content:center;
  font-size:25px;
  font-weight:bold;
  font-family:sans-serif;
}
.circular-loader:before,
.circular-loader:after {
  content:"";
  position:absolute;
  border-radius:50%;
}
.circular-loader:before {
  inset:0;
  background:
    radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.circular-loader:after {
  inset:calc(50% - var(--b)/2);
  background:var(--c);
  transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
  animation:p 1s .5s both;
}
.no-round:before {
  background-size:0 0,auto;
}
.no-round:after {
  content:none;
}
@keyframes p {
  from{--p:0}
}
/* 
breadCrumbs */
.steps {
  list-style: none;
  display: flex;
}
.steps .step {
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  background: rgb(220, 232, 247);
  position: relative;
  height: 50px;
  line-height: 50px;
  margin-right: 30px;
  padding: 0 20px;
}
.steps .step > a {
  text-decoration: none;
  color: white;
}
.steps .step:last-child {
  margin-right: 0;
}
.steps .step::before {
  transition: 0.3s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: -25px;
  border-left: 25px solid transparent;
  border-top: 25px solid rgb(220, 232, 247);
  border-bottom: 25px solid rgb(220, 232, 247);
}
.steps .step:first-child::before {
  display: none;
}
.steps .step::after {
  transition: 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: -25px;
  border-left: 25px solid rgb(220, 232, 247);
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  content: "";
}
.steps .step:last-child::after {
  display: none;
}
.steps .step:hover {
  background: rgb(123, 148, 181);
}
.steps .step:hover::before {
  border-top-color: rgb(123, 148, 181);
  border-bottom-color: rgb(123, 148, 181);
}
.steps .step:hover::after {
  border-left-color: rgb(123, 148, 181);
}
.steps .step:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.steps .step:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*Use Case CSS*/
.slick-use-case-player .slick-dots li
{
    position: relative;
    display: none;
    cursor: pointer;
}
.slick-use-case-player .slick-list{
  padding-bottom:10px
}
.slick-use-case-player .slick-dots li.slick-active
{
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    color: #7B94B5;
    font-weight: 500;
    top: 5px;
}
/* .slick-satalite-image .slick-slide { transform: translate(100%, 0);width: 250px!important; } */
.pricing-mobile-feacture {
  visibility:hidden;
  overflow:hidden;
  transition:0.5s;
  padding-right: 10px;
  -webkit-transition:0.5s;
  display: none;
}
.pricing-mobile-feacture.pricing-mobile-feacture-visible {
  visibility:visible;
  display: block;
}

.HeadingGradient {
  font-size: 44;
  color:red;
  font-family: "Poppins";
  font-weight: "700";
  background-size: 100%;
  background: linear-gradient(to right,  #A177F5,#5975EE );
  background-clip: 'text';    
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}